import styled from 'styled-components'
import moment from 'moment'

import { grey, red } from '../../../theme'
import { Performance } from '../../../api-models'
import CalculatedTooltip from './CalculatedTooltip'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: break-spaces;
  color: ${grey[700]};
  font-size: 12px;
`

const ColorWrapper = styled.span<{ shouldHighlight: boolean }>`
  ${(props) => (props.shouldHighlight ? `color: ${red[800]}` : '')};
`

const formatSelectedPeriod = (period: Performance.Common.Period) => {
  const selectedPeriod = { from: '', to: '', duration: '' }
  selectedPeriod.from = moment.utc(period.from).format('DD MMM HH:mm UTC')
  selectedPeriod.to = moment.utc(period.to).format('DD MMM HH:mm UTC')

  const ms = moment.duration(moment(period.to).diff(moment(period.from)))

  selectedPeriod.duration = `${ms.days() ? ms.days() + 'd ' : ''}${
    ms.hours() ? ms.hours() + 'h ' : ''
  }${ms.minutes() ? ms.minutes() + 'm' : ''}`.trimEnd()

  return selectedPeriod
}

type Props = {
  queryPeriod: Performance.Common.Period
  resultPeriod: Performance.Common.Period
}

export const SelectedPeriod = ({ queryPeriod, resultPeriod }: Props) => {
  const { from, to, duration } = formatSelectedPeriod(resultPeriod)
  const periodsAreNotEqual =
    queryPeriod.from !== resultPeriod.from || queryPeriod.to !== resultPeriod.to

  return (
    <Wrapper>
      Selected period: {from} –{' '}
      <ColorWrapper shouldHighlight={periodsAreNotEqual}>{to}</ColorWrapper>
      {` (${duration})`}
      {periodsAreNotEqual && <CalculatedTooltip />}
    </Wrapper>
  )
}
