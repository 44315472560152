import { useContext } from 'react'
import moment from 'moment/moment'

import { isEditWindowLimited } from '../utils'
import {
  StyledMcNotification,
  Wrapper,
} from '../../../components/Notifications/Notifications.styles'
import { VesselPageContext, WindowContext } from '../../../contexts'
import { useGetLatestSubmittedNoonReport } from '../../../queries/GandalfApi/GandalfApi'

const EditWindowLimitedNotification = () => {
  const { windowSize } = useContext(WindowContext)
  const imoNo = useContext(VesselPageContext).imoNo!

  const getLatestSubmittedNoonReport = useGetLatestSubmittedNoonReport(imoNo)

  if (!getLatestSubmittedNoonReport.isSuccess) {
    return null
  }

  const latestSubmittedNoonReport = getLatestSubmittedNoonReport.data

  if (latestSubmittedNoonReport === null) {
    return null
  }

  if (!isEditWindowLimited(moment.utc(latestSubmittedNoonReport.periodStart))) {
    return null
  }

  return (
    <Wrapper>
      <StyledMcNotification
        fit={windowSize}
        appearance='warning'
        icon='exclamation-triangle'
        heading='Please note that the reports from previous year will be locked for editing from January 15th'
        body='If you for some reason are behind with reporting, and have not yet submitted any reports this year, you can continue to add/edit reports from last year as usual. Reports from last year will then stay open for editing till January 15th or when you submit your first report with start date this year.'
      />
    </Wrapper>
  )
}

export default EditWindowLimitedNotification
