import {
  type QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import moment from 'moment'
import type { IDateRangeValue } from '@maersk-global/mds-components-core/mc-date-range/types'

import {
  displayToast,
  doDelete,
  doGet,
  doPatch,
  doPost,
  doPut,
  getGandalfUrl,
  getGandalfV2ShoreUrl,
  getGandalfV2Url,
} from '../../utils'
import { type IReport } from '../../api-models/hdc/report'

const STALE_TIME_12_HOURS = 1000 * 60 * 60 * 12
const STALE_TIME_3_HOURS = 1000 * 60 * 60 * 3
const STALE_TIME_10_MINUTES = 1000 * 60 * 10

const keys = {
  All: [
    { Gandalf: 'report' },
    { Gandalf: 'reports' },
    { Gandalf: 'vesselClassMetcReports' },
    { Gandalf: 'vesselClassMetcReportSummaries' },
    { Gandalf: 'eventTypes' },
    { Gandalf: 'latestSubmittedNoonReport' },
  ] as const,
  report: (imo: string, id: string, type: GandalfApi.ReportType) =>
    [{ ...keys.All[0], imo, id, type }] as const,
  reports: (imo: string, type: GandalfApi.ReportType) =>
    [{ ...keys.All[1], imo, type }] as const,
  vesselClassMetcReports: (reportIds: Array<string>) =>
    [{ ...keys.All[2], reportIds }] as const,
  vesselClassMetcReportSummaries: (
    imoNo: string,
    dateRange?: IDateRangeValue,
  ) => [{ ...keys.All[3], imoNo, dateRange }] as const,
  eventTypes: () => [{ ...keys.All[4] }] as const,
  latestSubmittedNoonReport: (imo: string) =>
    [{ ...keys.All[5], imo }] as const,
}

export const useGetReport = <T>(
  imo: string,
  id: string,
  type: GandalfApi.ReportType,
) => {
  return useQuery({
    queryKey: keys.report(imo, id, type),
    queryFn: getReport<T>,
    staleTime: STALE_TIME_10_MINUTES,
  })
}

const getReport = async <T>({
  queryKey: [{ imo, id, type }],
}: QueryFunctionContext<ReturnType<(typeof keys)['report']>>): Promise<
  GandalfApi.ReportResponse<T>
> => {
  return doGet(`${getGandalfV2Url()}/vessels/${imo}/reports/${id}?type=${type}`)
}

export const useGetVesselClassMetcReports = (reportIds: Array<string>) => {
  return useQuery({
    queryKey: keys.vesselClassMetcReports(reportIds),
    queryFn: getVesselClassMetcReports,
    staleTime: STALE_TIME_12_HOURS,
  })
}

const getVesselClassMetcReports = async ({
  queryKey: [{ reportIds }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['vesselClassMetcReports']>
>): Promise<Array<GandalfApi.MetcReport>> => {
  const url = new URL(
    `${getGandalfV2ShoreUrl()}/shore/vessel-class-metc-reports`,
  )
  reportIds.forEach((id) => {
    url.searchParams.append(`id`, id)
  })

  return doGet(url.toString())
}

export const useGetVesselClassMetcReportSummaries = (
  imoNo: string,
  dateRange?: IDateRangeValue,
) => {
  return useQuery({
    queryKey: keys.vesselClassMetcReportSummaries(imoNo, dateRange),
    queryFn: getVesselClassMetcReportSummaries,
    staleTime: STALE_TIME_12_HOURS,
    enabled: !!dateRange?.from && !!dateRange.to,
    meta: {
      gandalfError: 'A problem occurred retrieving reports.',
    },
  })
}

const getVesselClassMetcReportSummaries = async ({
  queryKey: [{ imoNo, dateRange }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['vesselClassMetcReportSummaries']>
>): Promise<Array<GandalfApi.EngineTestReportSummary>> => {
  const searchParams = new URLSearchParams({
    startDate: moment.utc(dateRange!.from!).toISOString(),
    endDate: moment.utc(dateRange!.to!).toISOString(),
  })
  return doGet(
    `${getGandalfV2ShoreUrl()}/shore/vessel-class-metc-report-summaries?imoList=${imoNo}&${searchParams.toString()}`,
  )
}

export const useGetReports = <T>(imo: string, type: GandalfApi.ReportType) => {
  return useQuery({
    queryKey: keys.reports(imo, type),
    queryFn: getReports<T>,
    staleTime: STALE_TIME_10_MINUTES,
  })
}

const getReports = async <T>({
  queryKey: [{ imo, type }],
}: QueryFunctionContext<ReturnType<(typeof keys)['reports']>>): Promise<
  GandalfApi.ReportsResponse<T>
> => {
  return doGet(`${getGandalfV2Url()}/vessels/${imo}/reports?type=${type}`)
}

export const usePostReport = (imo: string) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: postReport(imo),
    onSuccess: (_, vars) => {
      void queryClient.invalidateQueries({
        queryKey: keys.reports(imo, vars.report.reportType),
        refetchType: 'all',
      })
    },
    onError: handlePostError,
  })
}

const postReport =
  (imo: string) => (payload: GandalfApi.CreateReportRequest) => {
    return doPost(`${getGandalfV2Url()}/vessels/${imo}/reports/draft`, payload)
  }

const handlePostError = (error: GandalfApi.Error) => {
  void displayToast('error', 'Failed to create report', error.body.error, 8000)
}

export const usePatchReport = (
  imo: string,
  id: string,
  type: GandalfApi.ReportType,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: patchReport(imo, id),
    onSuccess: async () => {
      void queryClient.invalidateQueries({
        queryKey: keys.report(imo, id, type),
        refetchType: 'active',
      })
      await queryClient.invalidateQueries({
        queryKey: keys.reports(imo, type),
        refetchType: 'all',
      })
    },
    onError: (err: GandalfApi.ValidationError) => {
      handlePatchError(err)
      void queryClient.invalidateQueries({
        queryKey: keys.report(imo, id, type),
        refetchType: 'none',
      })
    },
  })
}

type Patch = {
  section: string
  payload: GandalfApi.Report<any>
}

const patchReport = (imo: string, id: string) => (p: Patch) => {
  return doPatch(
    `${getGandalfV2Url()}/vessels/${imo}/reports/${id}/${p.section}`,
    p.payload,
  )
}

const handlePatchError = (_: GandalfApi.ValidationError) => {
  void displayToast(
    'error',
    'Failed to update report',
    'Please make sure all report errors are resolved and try again.',
    8000,
  )
}

export const usePutReport = (
  imo: string,
  id: string,
  type: GandalfApi.ReportType,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: putReport(imo, id),
    onSuccess: async () => {
      void queryClient.invalidateQueries({
        queryKey: keys.report(imo, id, type),
        refetchType: 'none',
      })
      await queryClient.invalidateQueries({
        queryKey: keys.reports(imo, type),
        refetchType: 'all',
      })
    },
    onError: (err: GandalfApi.ValidationError) => {
      handlePutError(err)
      void queryClient.invalidateQueries({
        queryKey: keys.report(imo, id, type),
        refetchType: 'none',
      })
    },
  })
}

const putReport =
  (imo: string, id: string) => (payload: GandalfApi.Report<any>) => {
    return doPut(`${getGandalfV2Url()}/vessels/${imo}/reports/${id}`, payload)
  }

const handlePutError = (_: GandalfApi.ValidationError) => {
  void displayToast(
    'error',
    'Failed to submit report',
    'Please make sure all report errors are resolved and try again.',
    8000,
  )
}

export const useDeleteReport = (imo: string, type: GandalfApi.ReportType) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteReport(imo, type),
    onError: (error) => void handleDeleteError(error),
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: keys.reports(imo, type),
        refetchType: 'active',
      })
    },
  })
}

const deleteReport =
  (imo: string, type: GandalfApi.ReportType) => (id: string) => {
    return doDelete(
      `${getGandalfV2Url()}/vessels/${imo}/reports/${id}?type=${type}`,
    )
  }

const handleDeleteError = (error: any) => {
  let message = ''
  if (typeof error === 'string') {
    message = error
  }

  if (error.body?.error && typeof error.body.error === 'string') {
    message = error.body.error
  } else if (error.message && typeof error.message === 'string') {
    message = error.message
  }

  void displayToast('error', 'Failed to delete report', message, 8000)
}

export const useGetEventTypes = () => {
  return useQuery({
    queryKey: keys.eventTypes(),
    queryFn: getEventTypes,
    staleTime: STALE_TIME_3_HOURS,
  })
}

const getEventTypes = async (): Promise<GandalfApi.EventTypesResponse> => {
  return doGet(`${getGandalfUrl()}/eventtypes`)
}

export const useGetLatestSubmittedNoonReport = (imo: string) => {
  return useQuery({
    queryKey: keys.latestSubmittedNoonReport(imo),
    queryFn: getLatestSubmittedNoonReport,
    staleTime: STALE_TIME_3_HOURS,
  })
}

const getLatestSubmittedNoonReport = async ({
  queryKey: [{ imo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['latestSubmittedNoonReport']>
>): Promise<IReport | null> => {
  return doGet(
    `${getGandalfV2Url()}/vessels/${imo}/latest-submitted-noon-report`,
  )
}

export const isGandalfError = (error: unknown): error is GandalfApi.Error => {
  return (
    (error as GandalfApi.Error).body?.error !== undefined &&
    (error as GandalfApi.Error).statusCode !== undefined &&
    (error as GandalfApi.Error).statusText !== undefined
  )
}
