import React from 'react'
import { type EChartOption, init } from 'echarts'

import { Performance } from '../../../api-models'
import { formatFuelTypes, getEngineTheme } from '../auxiliary-util'
import { grey } from '../../../theme'
import { formatValue, stripDecimals, tooltip } from '../../../utils'
import { type Chart } from '../../../utils/models'
import {
  ACTIVATE_ZOOM_ACTION,
  AXIS_LABEL,
  AXIS_LINE,
  AXIS_SPLIT_LINE,
  AXIS_TICK,
  GRID,
  TOOLBOX_TIMELINE,
  UNITS,
  X_AXIS_TIMELINE,
} from '../../../utils/constants'

type Props = {
  data: Performance.Sfoc.AeSfocData
  showEngine: Array<boolean>
  chartData: Array<Array<number | undefined>>
  timeLineMinValue: number
  dimensions: Array<string>
}

type State = {}

export class AePowerVsTimeChart extends React.Component<Props, State> {
  private chartNode: React.RefObject<HTMLDivElement>
  private chart?: Chart

  constructor(props: Props) {
    super(props)

    this.state = {}
    this.chartNode = React.createRef()
  }

  getOptions = (data: any): EChartOption => {
    const { showEngine, chartData, timeLineMinValue, dimensions } = this.props
    let adviceTipRightSide = true

    return {
      grid: {
        ...GRID,
        bottom: 60,
      },
      xAxis: {
        ...X_AXIS_TIMELINE,
      },
      yAxis: [
        {
          id: 'power-vs-time',
          type: 'value',
          axisTick: AXIS_TICK,
          axisLine: { ...AXIS_LINE, onZero: true },
          splitLine: AXIS_SPLIT_LINE,
          axisLabel: {
            formatter: (value: any) => stripDecimals(value, 1),
            ...AXIS_LABEL,
          },
          min: 0,
          max: this.getMaxChartValue(chartData),
        },
      ],
      dataset: [
        {
          id: 'power-vs-time',
          source: chartData,
          dimensions: dimensions,
        },
        { id: 'data', source: data },
      ],
      legend: {
        show: false,
        selected: {
          Mix: showEngine[0],
          AE1: showEngine[1],
          AE2: showEngine[2],
          AE3: showEngine[3],
          AE4: showEngine[4],
          AE5: showEngine[5],
        },
      },

      toolbox: TOOLBOX_TIMELINE,
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'line',
          axis: 'x',
        },
        textStyle: {
          color: grey[50],
        },
        backgroundColor: 'rgba(50,50,50,0.9)',
        position: (pos, params, dom, rect, size: any) => {
          const obj: any = { top: size.viewSize[1] / 4 }
          adviceTipRightSide = pos[0] < size.viewSize[0] / 2
          const xPos = adviceTipRightSide
            ? +pos[0] + 20
            : size.viewSize[0] - (+pos[0] - 20)
          obj[['right', 'left'][+adviceTipRightSide]] = xPos
          return obj
        },
        formatter: (params: any) => {
          // dataIndex relates to the actual index in the original arrays
          let dataIndex = params[0].dataIndex

          let labels: Array<{
            [key: string]: string | number
          }> = []

          data.runningEnginesSeries[dataIndex].forEach(
            (entry: Performance.Sfoc.RunningEnginesSerie) => {
              labels.push({
                label: `AE ${entry.number} Load`,
                value: formatValue(entry.load, 1),
                unit: '%',
              })
            },
          )

          data.runningEnginesSeries[dataIndex].forEach(
            (entry: Performance.Sfoc.RunningEnginesSerie) => {
              labels.push({
                label: `AE ${entry.number} Power`,
                value: formatValue(entry.power, 1),
                unit: UNITS.KILO_WATT,
              })
            },
          )

          labels.push(
            {
              label: 'Consumption',
              value: formatValue(data.consumptionSeries[dataIndex] * 1000, 0),
              unit: UNITS.KG_PER_HOUR,
            },
            {
              label: 'SFOC',
              value: formatValue(data.sfocSeries[dataIndex], 1),
              unit: UNITS.SFOC,
            },
            {
              label: 'Fuel Type',
              value: formatFuelTypes(data.fuelTypesSeries[dataIndex]),
              unit: '',
            },
          )
          return tooltip(data.timestamps[dataIndex], labels)
        },
      },
      dataZoom: [
        {
          type: 'slider',
          startValue: timeLineMinValue,
          minValueSpan: 3600 * 12 * 1000,
          zoomOnMouseWheel: false,
          bottom: 20,
        },
      ],
      series: ['Mix', 'AE1', 'AE2', 'AE3', 'AE4', 'AE5'].map((engineId) => {
        const seriesColor = getEngineTheme(engineId, true)
        return {
          id: engineId,
          name: engineId,
          type: 'line',
          stack: engineId === 'Mix' ? 2 : 1,
          symbol: 'none',
          z: engineId === 'Mix' ? 1 : 30,
          encode: {
            y: engineId === 'Mix' ? 'totalpower' : `power${engineId}`,
            x: 'timestamp',
          },
          areaStyle: {
            color: seriesColor.fill,
          },
          lineStyle: {
            color: seriesColor.stroke,
          },
        }
      }),
    }
  }

  getMaxChartValue(chartData: Array<Array<number | undefined>>): number {
    return Math.floor(
      chartData.reduce((prevVal: number, current: any, i: number, arr: any) => {
        return Math.max(
          prevVal,
          current[0] || -1,
          current[6] || -1,
          current[7] || -1,
          current[8] || -1,
          current[9] || -1,
          current[10] || -1,
        )
      }, Number.NEGATIVE_INFINITY) * 1.2,
    )
  }

  zoomIn = (action: object) => {
    if (this.chart) {
      this.chart.dispatchAction(action, true)
    }
  }

  zoomOut = (silent?: boolean) => {
    if (this.chart) {
      this.chart.dispatchAction(
        {
          type: 'dataZoom',
          startValue: this.props.timeLineMinValue,
          endValue:
            this.props.data.timestamps[this.props.data.timestamps.length - 1],
        },
        !!silent,
      )
    }
  }

  componentDidMount() {
    const { data } = this.props
    if (this.chartNode.current) {
      const chart = init(this.chartNode.current) as Chart
      chart.setOption(this.getOptions(data), { lazyUpdate: true })
      chart.dispatchAction(ACTIVATE_ZOOM_ACTION)
      chart.group = 'ae-sfoc'
      this.chart = chart
      this.chart.on('dataZoom', this.zoomIn)
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { data, showEngine } = this.props
    if (this.chart && prevProps.data !== data) {
      this.chart.setOption(this.getOptions(data))
    } else if (this.chart && showEngine !== prevProps.showEngine) {
      showEngine.forEach((engine, index) => {
        if (this.chart && engine !== prevProps.showEngine[index]) {
          this.chart.dispatchAction({
            type: 'legendToggleSelect',
            name: index === 0 ? 'Mix' : `AE${index}`,
          })
        }
      })
    }
  }

  public render() {
    return (
      <div
        onDoubleClick={() => {
          this.zoomOut(false)
        }}
        id='aepower-vs-time'
        ref={this.chartNode}
      />
    )
  }
}
