import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { type IMcNotification } from '@maersk-global/mds-components-core/mc-notification/types'

import { ContentCard } from '../../commons'
import Notifications from '../../components/Notifications/Notifications'
import TemNotifications from '../../components/TemNotifications/TemNotifications'
import PerformanceAlerts from '../../components/PerformanceAlerts/PerformanceAlerts'
import HDCReportingOptions from '../../features/hybrid-data-collector/components/reporting-options'
import { ReportsOverviewComponent } from '../../features/hybrid-data-collector/components/reports-table'
import EditWindowLimitedNotification from '../../features/hybrid-data-collector/components/EditWindowLimitedNotification'
import { HDCContext } from '../../features/hybrid-data-collector/context'
import { ContentLayout } from '../../layout'
import { getFuelConsumptionStatus } from '../../services/performance'
import { displayErrorModal } from '../../utils'
import { getFuelDataLossAlertIMcNotification } from './report.utils'

const HDCOverviewPage = () => {
  const { imoNo, resetState, setFuelDataLosses } = useContext(HDCContext)
  const [alerts, setAlerts] = useState<Array<ReactNode | IMcNotification>>([])

  const dataLossNotification: IMcNotification = useMemo(
    () => getFuelDataLossAlertIMcNotification(imoNo),
    [imoNo],
  )

  const getNotifications = useCallback(async () => {
    try {
      const { fuelLineDataLosses, hasDataLoss } =
        await getFuelConsumptionStatus(imoNo)
      setAlerts(hasDataLoss ? [dataLossNotification] : [])
      setFuelDataLosses(hasDataLoss ? fuelLineDataLosses : [])
    } catch (err) {
      displayErrorModal({
        statusText: 'Could not fetch stock and fuel status',
        message: err.message || 'Please try to refresh the page',
      })
      setAlerts([])
      setFuelDataLosses([])
    }
  }, [dataLossNotification, imoNo, setFuelDataLosses])

  useEffect(() => {
    getNotifications()
  }, [getNotifications])

  useEffect(resetState, [resetState])

  return (
    <ContentLayout header='Reporting'>
      <EditWindowLimitedNotification />
      <Notifications alerts={alerts} />
      <TemNotifications />
      <PerformanceAlerts />
      <div className='full-component'>
        <HDCReportingOptions />
        <ContentCard
          id='reports-table'
          title='Reports overview'
          helpTextKey='hdc/reports-table'
        >
          <ReportsOverviewComponent />
        </ContentCard>
      </div>
    </ContentLayout>
  )
}

export default HDCOverviewPage
