import { McButton, McTooltip } from '@maersk-global/mds-react-wrapper'
import styled from 'styled-components'

import { grey } from '../../../theme'

const CalculatedTooltip = () => {
  return (
    <McTooltip position='top-right' fit='small' open>
      <StyledMcButton
        hiddenlabel
        slot='trigger'
        fit='small'
        icon='info-circle'
        appearance='neutral'
        variant='plain'
        padding='none'
      />
      <span>
        Data is not available for the full period you have selected.
        <br />
        We have therefore adjusted it to match availability.
      </span>
    </McTooltip>
  )
}

export default CalculatedTooltip

const StyledMcButton = styled(McButton)`
  ::part(button) {
    color: ${grey[700]};
    padding: 4px 8px;
  }

  ::part(icon) {
    fill: ${grey[700]};
  }
`
