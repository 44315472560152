import { IColorScheme, red } from '../../theme'
import * as colors from './themes'

export const getEngineTheme: (
  engineId: number | string,
  timelineColors?: boolean,
) => IColorScheme = (engineId, timelineColors = false) => {
  switch (engineId) {
    case 1:
    case 'AE1':
      return colors.auxEng1
    case 2:
    case 'AE2':
      return colors.auxEng2
    case 3:
    case 'AE3':
      return colors.auxEng3
    case 4:
    case 'AE4':
      return colors.auxEng4
    case 5:
    case 'AE5':
      return colors.auxEng5
    case 0:
    case 'Mix':
      return timelineColors ? colors.auxMixInvisible : colors.auxMix
    default:
      return colors.auxEng1
  }
}

export const formatFuelTypes: (fuelTypes: number) => string = (
  fuelTypes: number,
) => {
  const results: string[] = []

  if ((fuelTypes & 1) !== 0) {
    results.push('HS')
  }

  if ((fuelTypes & 2) !== 0) {
    results.push('ULS')
  }

  if ((fuelTypes & 4) !== 0) {
    results.push('MDO')
  }

  if ((fuelTypes & 8) !== 0) {
    results.push('VLS')
  }

  if ((fuelTypes & 0x10) !== 0) {
    results.push('MM')
  }

  if (results.length) {
    return results.join(' + ')
  }

  return '-'
}

export const createTooltipAdvice: (
  advice: string,
  adviceTipRightSide: boolean,
) => string = (advice: string, adviceTipRightSide: boolean) => {
  const element = `<div style="padding: 4px;display:flex; align-items: center; justify-content: center; position:absolute; top:0; ${
    adviceTipRightSide ? 'left: 101%' : 'right: 101%'
  }; height:25px; background-color:rgba(240, 159, 155, 0.9); border-radius:3px; color: ${
    red[900]
  }; border: 1px solid ${
    red[800]
  };"><span class="fal fa-info-circle" style="font-size:18px"></span>&nbsp; ${advice}</div>`

  return element
}
