import { type QueryFunctionContext, useQuery } from '@tanstack/react-query'

import { getSensorApiUrl, doPost, doGet } from '../../utils'

const STALE_TIME_10_MINUTES = 1000 * 60 * 10

const keys = {
  All: [
    { SensorStatus: 'version' },
    { SensorStatus: 'sensorStatuses' },
    { SensorStatus: 'sensorStatus' },
    { SensorStatus: 'sensorsData' },
    { SensorStatus: 'systemDataPointGroups' },
    { SensorStatus: 'currentErrors' },
    { SensorStatus: 'allSensors' },
  ] as const,
  version: () => [{ ...keys.All[0] }] as const,
  sensorStatuses: () => [{ ...keys.All[1] }] as const,
  sensorStatus: (imoNo: number) => [{ ...keys.All[2], imoNo }] as const,
  sensorsData: (imoNo: string, id: number) =>
    [{ ...keys.All[3], imoNo, id }] as const,
  systemDataPointGroups: (imoNo: string) =>
    [{ ...keys.All[4], imoNo }] as const,
  currentErrors: (imoNo: string) => [{ ...keys.All[5], imoNo }] as const,
  allSensors: (imoNo: string) => [{ ...keys.All[6], imoNo }] as const,
}

// export const useGetVersion = () => {
//   return useQuery({
//     queryKey: keys.version(),
//     queryFn: getVersion,
//     staleTime: STALE_TIME_10_MINUTES,
//   })
// }

export const getVersion = (): Promise<SensorStatusApi.Home.Version> =>
  doGet(`${getSensorApiUrl()}/Version`)

export const useGetSensorStatuses = (vesselImoNoList: Array<string>) => {
  return useQuery({
    queryKey: keys.sensorStatuses(),
    queryFn: () => getSensorStatuses(vesselImoNoList),
    staleTime: STALE_TIME_10_MINUTES,
    enabled: vesselImoNoList.length > 0,
  })
}

const getSensorStatuses = async (
  imoNos: Array<string>,
): Promise<Array<SensorStatusApi.Sensors.VesselSensorsStatus>> => {
  return doPost(`${getSensorApiUrl()}/Sensors/Status`, imoNos)
}

export const useGetSensorsStatus = (imoNo: number) => {
  return useQuery({
    queryKey: keys.sensorStatus(imoNo),
    queryFn: getSensorsStatus,
    staleTime: STALE_TIME_10_MINUTES,
    enabled: !!imoNo,
  })
}

const getSensorsStatus = async ({
  queryKey: [{ imoNo }],
}: QueryFunctionContext<
  ReturnType<(typeof keys)['sensorStatus']>
>): Promise<SensorStatusApi.Sensors.VesselSensorsStatus> => {
  return doGet(`${getSensorApiUrl()}/Sensors/Status?imoNo=${imoNo}`)
}

// export const useGetSensorsData = (imoNo: string, id: number) => {
//   return useQuery({
//     queryKey: keys.sensorsData(imoNo, id),
//     queryFn: () => getSensorsData(imoNo, id),
//     staleTime: STALE_TIME_10_MINUTES,
//   })
// }

export const getSensorsData = (imoNo: string, id: number) =>
  doGet(
    `${getSensorApiUrl()}/Sensors/Data?imoNo=${imoNo}&sensorId=${id}&hoursBackInTime=${168}`,
  )

// export const useGetSystemDataPointGroups = (imoNo: string) => {
//   return useQuery({
//     queryKey: keys.systemDataPointGroups(imoNo),
//     queryFn: () => getSystemDataPointGroups(imoNo),
//     staleTime: STALE_TIME_10_MINUTES,
//   })
// }

export const getSystemDataPointGroups = (
  imoNo: string,
): Promise<Array<SensorStatusApi.System.DataPointGroup>> =>
  doGet(`${getSensorApiUrl()}/System/DataPointGroups?imoNo=${imoNo}`)

// export const useGetCurrentErrors = (imoNo: string) => {
//   return useQuery({
//     queryKey: keys.currentErrors(imoNo),
//     queryFn: () => getCurrentErrors(imoNo),
//     staleTime: STALE_TIME_10_MINUTES,
//   })
// }

export const getCurrentErrors = (
  imoNo: string,
): Promise<SensorStatusApi.Sensors.CurrentErrorsResponse> =>
  doGet(`${getSensorApiUrl()}/Sensors/CurrentErrors?imoNo=${imoNo}`)

// export const useGetAllSensors = (imoNo: string) => {
//   return useQuery({
//     queryKey: keys.allSensors(imoNo),
//     queryFn: () => getAllSensors(imoNo),
//     staleTime: STALE_TIME_10_MINUTES,
//   })
// }

export const getAllSensors = (
  imoNo: string,
): Promise<SensorStatusApi.Sensors.AllSensorsResponse> =>
  doGet(`${getSensorApiUrl()}/Sensors/All?imoNo=${imoNo}`)
