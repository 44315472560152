import { createColumnHelper, type Row } from '@tanstack/react-table'

import { Dot } from './styles'

const columnHelper =
  createColumnHelper<SensorStatusApi.Sensors.SensorResponse>()

export const columns = [
  columnHelper.accessor('name', {
    header: 'Sensor name',
    cell: (props) => (
      <>
        <Dot error={props.row.original.hasError} />
        {props.getValue()}
      </>
    ),
  }),
  columnHelper.accessor('connector', {
    header: 'Connector',
    cell: (props) => props.getValue().name,
    filterFn: (row, columnId, connectorName: string) =>
      row.original.connector.name === connectorName,
  }),
  columnHelper.accessor('group', {
    header: 'Group',
  }),
]

export const getConnectorOptions = (
  sensors: Array<Row<SensorStatusApi.Sensors.SensorResponse>>,
) =>
  Array.from(
    sensors.reduce(
      (acc, currVal) => acc.add(currVal.original.connector.name),
      new Set<string>(),
    ),
  ).map((connectorName) => ({ label: connectorName, value: connectorName }))
