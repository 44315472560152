import { useContext } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { McNotification } from '@maersk-global/mds-react-wrapper'

import { WindowContext } from '../../../contexts'

type Props = {
  connectorError: SensorStatusApi.Sensors.ConnectorErrorResponse
}

const StyledMcNotification = styled(McNotification)`
  display: block;
  margin-bottom: 12px;
`

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const ConnectorErrorBar = ({ connectorError }: Props) => {
  const { windowSize } = useContext(WindowContext)

  return (
    <StyledMcNotification
      key={connectorError.name}
      fit={windowSize}
      appearance='error'
      icon='exclamation-circle'
      heading={connectorError.name + ' system offline'}
    >
      <Body>
        <span>
          Error detected {moment.utc(connectorError.error.timestamp).fromNow()}
        </span>
        <span>{connectorError.sensorsAffected.length} sensor(s) affected</span>
      </Body>
    </StyledMcNotification>
  )
}

export default ConnectorErrorBar
