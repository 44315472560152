import { Component, createContext, ReactNode } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import moment from 'moment'

import { Performance } from '../api-models'
import { Management } from '../api-models/management'
import { routerParams } from '../routes'
import { isShoreContext } from '../utils'
import {
  getConfigurations,
  getConnectedVesselsImoNo,
  getSystemMode,
  getSystemTime,
  getVesselConfiguration,
} from '../services/performance'

export interface AppContextInterface
  extends ShoreModeInterface,
    VesselModeInterface {}

interface ShoreModeInterface {
  imoNos?: string[]
  configurations?: Performance.VesselConfiguration.Configuration[]
  installations?: Management.Vessel.SoftwareComponentsResponse[]
  selectedFavouriteList?: number
  changeSelectedFavouriteList?: (index?: number, redirectUrl?: string) => void
  selectDefaultFavouriteList?: () => void
}

interface VesselModeInterface {
  configuration?: Performance.VesselConfiguration.Configuration
  vesselName?: string
  imoNo?: number
}

export const AppContext = createContext<AppContextInterface>({
  imoNos: [],
  selectedFavouriteList: 0,
  changeSelectedFavouriteList: (index?: number, redirectUrl?: string) => {},
  selectDefaultFavouriteList: () => {},
})

// Provider used in Shore/Fleet mode

interface FleetContextProviderProps extends RouteComponentProps<routerParams> {
  children?: ReactNode
}

interface FleetContextProviderState extends ShoreModeInterface {
  hasCheckedForFavouriteLists: boolean
}

class FleetContextProvider extends Component<
  FleetContextProviderProps,
  FleetContextProviderState
> {
  constructor(props: FleetContextProviderProps) {
    super(props)
    this.selectDefaultFavouriteList = this.selectDefaultFavouriteList.bind(this)
    this.state = {
      imoNos: [],
      changeSelectedFavouriteList: this.changeSelectedFavouriteList,
      selectDefaultFavouriteList: this.selectDefaultFavouriteList,
      hasCheckedForFavouriteLists: false,
    }
  }

  componentDidMount() {
    Promise.all([getConnectedVesselsImoNo(), synchronizeMomentWithPapi()]).then(
      ([imoNosResponse]) => {
        const imoNos = imoNosResponse.map((imoNo) => imoNo.toString())
        getConfigurations(imoNos).then((configurations) => {
          this.setState({
            imoNos: imoNos,
            selectedFavouriteList: this.getSelectedFavouriteListFromStorage(),
            configurations:
              configurations as Performance.VesselConfiguration.Configuration[],
          })
        })
      },
    )
  }

  getSelectedFavouriteListFromStorage(): number {
    let selectedUserListIndex = parseInt(
      window.localStorage.getItem('selectedUserListIndex') ?? '-1',
    )
    if (selectedUserListIndex === -1) {
      window.localStorage.setItem('selectedUserListIndex', '0')
      return 0
    }
    return selectedUserListIndex
  }

  changeSelectedFavouriteList = (index?: number, redirectUrl?: string) => {
    this.setState({ selectedFavouriteList: index }, () => {
      window.localStorage.setItem(
        'selectedUserListIndex',
        JSON.stringify(index),
      )
      const { history } = this.props
      history.push(redirectUrl || '/MaerskStarConnect/shore/vessel-overview')
    })
  }

  selectDefaultFavouriteList = () => {
    this.setState({ selectedFavouriteList: 0 }, () => {
      window.localStorage.setItem('selectedUserListIndex', '0')
    })
  }

  render() {
    return (
      <AppContext.Provider value={this.state as AppContextInterface}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

// Provider used in Vessel mode

interface VesselContextProviderProps {
  children?: ReactNode
}

interface VesselContextProviderState extends AppContextInterface {
  imoNo?: number
}

export class VesselContextProvider extends Component<
  VesselContextProviderProps,
  VesselContextProviderState
> {
  constructor(props: VesselContextProviderProps) {
    super(props)
    this.state = {
      imoNos: [],
    }
  }

  componentDidMount() {
    getSystemMode()
      .then((json: Performance.System.Mode) => {
        const imoNo = json.vessel.imoNo
        const vesselName = json.vessel.name

        this.setState({ imoNo, vesselName })

        return Promise.all([
          getVesselConfiguration(imoNo),
          synchronizeMomentWithPapi(),
        ])
      })
      .then(([configuration]) =>
        this.setState({
          configuration:
            configuration as Performance.VesselConfiguration.Configuration,
        }),
      )
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    )
  }
}

const synchronizeMomentWithPapi = (): Promise<any> => {
  return new Promise<void>((resolve, reject) => {
    getSystemTime().then((json: Performance.System.Clock) => {
      let offset = new Date(json.utcNow).getTime() - Date.now()
      moment.now = function () {
        return offset + Date.now()
      }
      resolve()
    })
  })
}

export const AppContextProvider = isShoreContext()
  ? withRouter(FleetContextProvider)
  : VesselContextProvider
export const AppContextConsumer = AppContext.Consumer
