import { useContext } from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { McIcon } from '@maersk-global/mds-react-wrapper'

import styled, { lg, md } from '../../theme'
import { routerParams } from '../../routes'
import { getIsActivePageFunctionForVesselSidebar } from '../../utils'
import { Icon } from '../../commons'
import { AppContext } from '../../contexts'
import { usePageProps } from '../../routing/VesselRoutes.utils'
import SensorErrorCount from './SensorErrorCount'

interface BarContentVesselProps extends RouteComponentProps<routerParams> {
  vesselName: string | undefined
  imoNo: number | undefined
}

const Wrapper = styled.div`
  position: fixed;
  top: ${lg.BIG_BAR_HEIGHT};
  bottom: 0;
  overflow-y: auto;
  width: ${md.NAVBAR_WIDTH};
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    width: ${lg.NAVBAR_WIDTH};
  }
`

const NavName = styled.span``

const GroupNameItem = styled.li`
  cursor: default;
  font-weight: bold;

  > ${NavName} {
    padding: 8px 16px;
  }
`

const VesselTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  background: ${(props) => props.theme.colors.blue[300]};
  color: white;
`

export const NavItem = styled.li`
  a,
  a:visited {
    color: ${(props) => props.theme.colors.grey[700]};
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-left: white 0.4rem solid;
    transition: border-left 0.1s linear;

    &.active {
      background-color: ${(props) => props.theme.colors.grey[200]};
      color: ${(props) => props.theme.colors.grey[900]};
      border-left-color: ${(props) => props.theme.colors.blue[300]};
    }

    &:hover:not(.active) {
      background-color: ${(props) => props.theme.colors.grey[100]};
    }

    ${NavName} {
      flex: 1 1 auto;
    }

    span {
      &:nth-child(3) {
        text-align: center;
      }
    }
  }
`

const Navigation = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${NavItem} {
    ${NavName},
    .nav-item-arrow {
      display: block;
    }

    .nav-item-icon {
      display: block;
      margin: 0 auto;
    }
  }
`

const BarContentVessel = (props: BarContentVesselProps) => {
  const configuration = useContext(AppContext).configuration
  const { vesselName, imoNo } = props
  const pageProps = usePageProps(imoNo?.toString())

  return (
    <Wrapper>
      <VesselTitle>
        <McIcon icon={'vessel-front'} color={'white'} />
        {vesselName || 'Unknown Vessel'}
      </VesselTitle>
      <Navigation>
        {pageProps.map((page, idx) => {
          if (page.isGroupName) {
            return (
              <GroupNameItem key={`${page.name}-${idx}`}>
                <NavName>{page.name}</NavName>
              </GroupNameItem>
            )
          }
          const isEquipmentInstalled =
            !page.equipment || configuration?.[page.equipment].isInstalled
          if (page.hideInNav || page.isDisabled || !isEquipmentInstalled) {
            return null
          }
          return (
            <NavItem key={`page-1-${idx}`}>
              <NavLink
                to={`/MaerskStarConnect/vessel/${imoNo}${page.pagePath}${props.location.search}`}
                isActive={getIsActivePageFunctionForVesselSidebar(
                  page.pagePath,
                  imoNo,
                  page.isExact,
                )}
              >
                {page.icon && (
                  <Icon icon={page.icon} className='nav-item-icon' />
                )}
                <NavName>{page.name}</NavName>
                {page.name.toLowerCase() === 'sensors' && imoNo && (
                  <SensorErrorCount imoNo={imoNo} />
                )}
              </NavLink>
            </NavItem>
          )
        })}
      </Navigation>
    </Wrapper>
  )
}

export default withRouter(BarContentVessel)
