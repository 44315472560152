import ErrorCount from '../../components/ErrorCount'
import { useGetSensorsStatus } from '../../queries/SensorStatusApi/SensorStatusApi'

type Props = {
  imoNo: number
}

const SensorErrorCount = ({ imoNo }: Props) => {
  const sensorsStatus = useGetSensorsStatus(imoNo)

  if (!sensorsStatus.isSuccess || sensorsStatus.data.sensorErrorCount <= 0)
    return null

  return <ErrorCount>{sensorsStatus.data.sensorErrorCount}</ErrorCount>
}

export default SensorErrorCount
