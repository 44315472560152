import { createColumnHelper } from '@tanstack/react-table'
import moment from 'moment'

const columnHelper = createColumnHelper<
  | SensorStatusApi.Sensors.SensorResponse
  | SensorStatusApi.Sensors.ConnectorErrorResponse
>()

export const columns = [
  columnHelper.accessor('name', {
    header: 'Sensor name',
  }),
  columnHelper.accessor('error.message', {
    header: 'Error message',
  }),
  columnHelper.accessor('error.timestamp', {
    header: 'Registered',
    cell: (props) => moment.utc(props.getValue()).fromNow(),
  }),
]
