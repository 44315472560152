import { useContext } from 'react'

import styled, { lg, md } from '../../theme'
import BarContentVessel from './BarContentVessel'
import BarContentShore from './BarContentShore/BarContentShore'
import { AppContext } from '../../contexts'
import { isVesselContext } from '../../utils'

const Basebar = styled.nav`
  position: fixed;
  top: ${lg.BIG_BAR_HEIGHT};
  flex-shrink: 0;
  background-color: white;
  color: ${(props) => props.theme.colors.grey[900]};
  border-right: 1px solid ${(props) => props.theme.colors.grey[200]};
  height: 100vh;
  width: ${md.NAVBAR_WIDTH};
  @media (min-width: ${(props) => props.theme.breakPoints.medium}px) {
    width: ${lg.NAVBAR_WIDTH};
  }
`

export const SidebarNav = (props) => {
  const appContext = useContext(AppContext)

  return (
    <Basebar id='sidebar'>
      {isVesselContext() ? (
        <BarContentVessel
          {...props}
          vesselName={appContext.vesselName}
          imoNo={appContext.imoNo}
        />
      ) : (
        <BarContentShore {...appContext} />
      )}
    </Basebar>
  )
}
