//TODO: Remove aetcPilotVesselsList, testbedsImoList & corresponding code, once ticket SV-3088(enabling AETC Reporting without Models available) is done
// Allowing testbed Imos for development purposes.

export const aetcPilotVesselsList = [
  9525493, 9525455, 9526459, 9525479, 9525302, 9525326, 9525314, 9525352,
  9529255, 9525467, 9525388, 9525376, 9525338, 9525364, 9525390, 9525285,
  9525340, 9525405, 9525297, 9561497, 9525481, 9561485, 9315238, 9315252,
  9315226, 9315240, 9315214,
]

export const testbedsImoList = [
  2000101, 2000102, 2000103, 2000104, 2000105, 2000201, 2000202, 2000203,
  2000204, 2000205,
]
