import { useMemo, useRef, useState } from 'react'
import {
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  type Row,
  type SortingState,
  useReactTable,
} from '@tanstack/react-table'

import SensorDetails from '../../sensor-details/SensorDetails'
import { columns } from './helpers'
import { PadContent } from '../../../../layout/styles'
import Table, { type ITableRef } from '../../../../components/Table/Table'
import AllSensorsFilters from './AllSensorsFilters'

type Props = {
  sensors: Array<SensorStatusApi.Sensors.SensorResponse>
  sensorErrors: Array<SensorStatusApi.Sensors.SensorResponse>
}

const AllSensors = (props: Props) => {
  const { sensors, sensorErrors } = props

  const [sorting, setSorting] = useState<SortingState>([
    { desc: false, id: 'name' },
  ])
  const [clickedSensor, setClickedSensor] =
    useState<SensorStatusApi.Sensors.SensorResponse>()

  const tableRef = useRef<ITableRef>(null)

  const data = useMemo(
    () =>
      sensors.map((sensor) => ({
        ...sensor,
        ...(sensor.hasError && { feedback: 'danger' }),
      })),
    [sensors],
  )

  const table = useReactTable({
    data,
    columns,
    initialState: {
      pagination: { pageSize: 25 },
    },
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  })

  const handleRowClick = (row: Row<SensorStatusApi.Sensors.SensorResponse>) => {
    setClickedSensor(row.original)
  }

  const handleModalClosed = () => {
    setClickedSensor(undefined)
    tableRef.current?.deselectRow()
  }

  return (
    <PadContent>
      <AllSensorsFilters table={table} />
      <Table table={table} onRowSelect={handleRowClick} ref={tableRef} />
      {clickedSensor && (
        <SensorDetails
          sensor={clickedSensor}
          sensorError={
            sensorErrors.filter((err) => err.id === clickedSensor.id)[0]
          }
          onCancel={handleModalClosed}
        />
      )}
    </PadContent>
  )
}

export default AllSensors
