import { useQuery } from '@tanstack/react-query'

import { getVersion } from '../../queries/SensorStatusApi/SensorStatusApi'
import { displayErrorModal } from '../../utils'
import DataPointsPage from './DataPointsPage'
import DataPointsPageLegacy from './DataPointsPageLegacy'

const DataPointsPageEntry = () => {
  const versionQuery = useQuery(
    ['sensor_status__version'],
    () => getVersion(),
    { staleTime: Infinity },
  )

  if (versionQuery.isError) {
    void displayErrorModal(versionQuery.error as ResponseError)
    return null
  }

  if (!versionQuery.isSuccess) return null

  const isLegacySensorStatusApi = versionQuery.data.version.match(/^1\.6/)

  return isLegacySensorStatusApi ? <DataPointsPageLegacy /> : <DataPointsPage />
}

export default DataPointsPageEntry
