import React, { useMemo, useState } from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import moment from 'moment'
import {
  McButton,
  McIcon,
  McInputDate,
  McInputTime,
  McModal,
  McOption,
  McSelect,
} from '@maersk-global/mds-react-wrapper'

import {
  ContentWrapper,
  DateTimeWrapper,
  Duration,
  Error,
} from './CreateEngineTestModal.styles'
import {
  getEquipmentOptions,
  StaticModelTypeToReportType,
} from './CreateEngineTestModal.utils'
import {
  type Equipment,
  type EquipmentOption,
  type Props,
} from './CreateEngineTestModal.types'
import {
  aetcPilotVesselsList,
  testbedsImoList,
} from './CreateEngineTestModal.consts'

import { routerParams } from '../../../routes'
import { useVesselStaticModels } from '../../../queries/MasterDataApi/MasterDataApi'
import { StaticModelType } from '../../../queries/MasterDataApi/MasterDataApi.consts'
import { usePostReport } from '../../../queries/GandalfApi/GandalfApi'
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
} from '../../../components/formik/InputDateTime/InputDateTime.utils'
import { isShoreContext } from '../../../utils'

const CreateEngineTestModal = ({ open, onClose }: Props) => {
  const history = useHistory()
  const { url } = useRouteMatch()
  const { vesselId: imoNo } = useParams<routerParams>()

  const [startDate, setStartDate] = useState('')
  const [startTime, setStartTime] = useState('')
  const [endDate, setEndDate] = useState('')
  const [endTime, setEndTime] = useState('')
  const [equipment, setEquipment] = useState<Equipment>()
  const [isCreating, setIsCreating] = useState(false)

  const staticModels =
    useVesselStaticModels<MasterDataApi.StaticModels.MainEngStaticModelData>(
      +imoNo,
      [StaticModelType.MainEng, StaticModelType.AuxEng],
    )

  const postReport = usePostReport(imoNo)

  const start = moment.utc(`${startDate} ${startTime}`, DATE_TIME_FORMAT)
  const end = moment.utc(`${endDate} ${endTime}`, DATE_TIME_FORMAT)

  const handleCreateReport = async (event: MouseEvent) => {
    if (!event) {
      // Prevent firing request if event does not come from submit button (MDS bug).
      return
    }
    setIsCreating(true)
    try {
      const reportType = StaticModelTypeToReportType[equipment!.type]
      const payload: GandalfApi.CreateEngineTestReportRequest = {
        report: {
          reportType: reportType,
          periodStart: start.toISOString(),
          periodEnd: end.toISOString(),
          equipment: equipment!,
        },
      }
      const { reportId } = await postReport.mutateAsync(payload)

      history.push(`${url}/${reportType}/${reportId}`)
    } finally {
      setIsCreating(false)
    }
  }

  const handleEquipmentChange = ({ detail }: CustomEvent<EquipmentOption>) => {
    setEquipment(detail.value)
  }

  const staticModelOptions = useMemo(() => {
    if (!staticModels.data) return []
    return getEquipmentOptions(staticModels.data.staticModels)
  }, [staticModels.data])

  const isPeriodDefined = Boolean(startDate && startTime && endDate && endTime)
  const duration = isPeriodDefined ? moment.duration(end.diff(start)) : null
  const isEndAfterNow = end.isAfter()
  const isDurationValid = Boolean(
    duration && duration.asHours() <= 6 && duration.asHours() >= 1,
  )
  const isSubmitDisabled =
    isShoreContext() || !isDurationValid || isEndAfterNow || !equipment
  const showError = isPeriodDefined && (!isDurationValid || isEndAfterNow)

  return (
    <McModal
      hiddenclose
      backdropcloseactiondisabled
      heading='Add Engine Test Report'
      width='650px'
      height='520px'
      open={open}
      closed={onClose}
    >
      <p>
        Choose equipment for test report and specify a period between 1 and 6
        hours.
      </p>
      <ContentWrapper>
        <McSelect
          label='Equipment'
          focusstartanchor
          optionselected={handleEquipmentChange}
          data-e2e='Equipment'
        >
          <small>Main engine</small>
          {staticModelOptions
            .filter(({ value }) => value.type === StaticModelType.MainEng)
            .map(({ label, value }) => (
              <McOption key={`${value.type}_${value.instanceNo}`} value={value}>
                {label}
              </McOption>
            ))}
          {(aetcPilotVesselsList.includes(Number(imoNo)) ||
            testbedsImoList.includes(Number(imoNo))) && (
            <>
              <small>Auxiliary engine</small>
              {staticModelOptions
                .filter(({ value }) => value.type === StaticModelType.AuxEng)
                .map(({ label, value }) => (
                  <McOption
                    key={`${value.type}_${value.instanceNo}`}
                    value={value}
                  >
                    {label}
                  </McOption>
                ))}
            </>
          )}
        </McSelect>
        <DateTimeWrapper>
          <McInputDate
            width='100'
            label='Start date'
            value={startDate}
            max={moment.utc().add(5, 'hours').format(DATE_FORMAT)}
            input={(event: Event) =>
              setStartDate((event.target as HTMLInputElement).value)
            }
            data-e2e='StartDate'
          />
          <McInputTime
            width='100'
            label='Start time, UTC'
            value={startTime}
            input={(event: Event) =>
              setStartTime((event.target as HTMLInputElement).value)
            }
            data-e2e='StartTime'
          />
        </DateTimeWrapper>
        <DateTimeWrapper>
          <McInputDate
            width='100'
            label='End date'
            value={endDate}
            max={moment.utc().add(6, 'hours').format(DATE_FORMAT)}
            input={(event: Event) =>
              setEndDate((event.target as HTMLInputElement).value)
            }
            data-e2e='EndDate'
          />
          <McInputTime
            width='100'
            label='End time, UTC'
            value={endTime}
            input={(event: Event) =>
              setEndTime((event.target as HTMLInputElement).value)
            }
            data-e2e='EndTime'
          />
          {duration && duration.isValid() && (
            <Duration>
              {`Duration: ${
                duration.days() > 0 ? `${duration.days()}d` : ''
              } ${duration.hours()}h ${duration.minutes()}m`}
            </Duration>
          )}
        </DateTimeWrapper>
        {showError && (
          <Error data-e2e='errorMessage'>
            <McIcon icon='exclamation-triangle' />
            {isEndAfterNow
              ? 'Test period must not end in the future.'
              : !isDurationValid
              ? 'Test duration must be between 1 and 6 hours.'
              : ''}
          </Error>
        )}
      </ContentWrapper>
      <McButton
        label='Create report'
        loading={isCreating}
        disabled={isSubmitDisabled}
        slot='primaryAction'
        appearance='primary'
        click={handleCreateReport}
        data-e2e='createReportButton'
      />
      <McButton
        label='Cancel'
        slot='secondaryAction'
        appearance='neutral'
        variant='outlined'
        dialogaction='cancel'
      />
    </McModal>
  )
}

export default CreateEngineTestModal
