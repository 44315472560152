import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { VariableSizeList as List } from 'react-window'
import debounce from 'lodash/debounce'

import {
  getInitialState,
  getSelectedVesselIndexFromParams,
  getStyleSizeFromPropName,
  sortVesselList,
} from './BarContentShore.utils'
import { Wrapper } from './BarContentShore.styles'
import { maerskBlue } from '../../../theme'
import { pageSettings } from '../../../routing/VesselRoutes'
import {
  getConnectedVessels,
  getDisconnectedVessels,
  isConnectedVessel,
  searchUserList,
} from '../../../utils'
import VesselPanel from '../VesselPanel/VesselPanel'
import { ListDropdown } from '../ListDropdown'
import BarContentShoreOverview from '../BarContentShoreOverview'
import { InputFieldFilter, Loading } from '../../../commons'
import { type Management } from '../../../api-models/management'
import { type AppContextInterface } from '../../../contexts/app-context'
import { VesselFilterContext } from '../VesselFilterContext/VesselFilterContext'
import { useFavourites } from '../../../queries/ManagementApi/ManagementApi'
import { useVesselsBasicInfo } from '../../../queries/MasterDataApi/MasterDataApi'
import { getFavouriteLists } from '../../../queries/ManagementApi/ManagementApi.utils'
import { mapVesselMasterDataToVesselRegistry } from '../../../queries/MasterDataApi/MasterDataApi.utils'
import { useGetSensorStatuses } from '../../../queries/SensorStatusApi/SensorStatusApi'

type Props = AppContextInterface

type State = {
  expandedIndex?: number
  selectedIndex?: number
  loadingUserList: boolean
}

const BarContentShore = ({
  selectedFavouriteList,
  changeSelectedFavouriteList,
  imoNos,
}: Props) => {
  const params = useParams()
  // @ts-ignore
  const vesselId = params.vesselId as string
  const vesselsBasicInfo = useVesselsBasicInfo()
  const favourites = useFavourites()
  const { vesselFilters, applyVesselFilters } = useContext(VesselFilterContext)
  const sensorsStatuses = useGetSensorStatuses(imoNos!)

  const prevExpandedIndexRef = useRef<number>()
  const listRef = useRef<List>(null)

  const [state, setState] = useState<State>(getInitialState)

  const favouriteLists = useMemo(() => {
    const lists: Array<Management.Favourites.FavouriteList> = []

    if (vesselsBasicInfo.isSuccess) {
      lists.push({
        name: 'All Vessels',
        vessels: mapVesselMasterDataToVesselRegistry(vesselsBasicInfo.data),
      })

      if (favourites.isSuccess) {
        lists.push(...getFavouriteLists(favourites.data, vesselsBasicInfo.data))
      }
    }

    return lists
  }, [favourites.isSuccess, vesselsBasicInfo.isSuccess])

  const filteredUserList = useMemo(() => {
    const connectedImoNos = imoNos as Array<string>

    if (
      connectedImoNos.length === 0 ||
      favouriteLists.length === 0 ||
      selectedFavouriteList === undefined ||
      selectedFavouriteList >= favouriteLists.length
    ) {
      return []
    }

    const connectedVessels = getConnectedVessels(
      favouriteLists[selectedFavouriteList].vessels,
      connectedImoNos,
    )

    /* Skip sorting if list is more than 100 vessels long. */
    if (connectedVessels.length < 100) {
      connectedVessels.sort(sortVesselList)
    }

    const disconnectedVessels = getDisconnectedVessels(
      favouriteLists[selectedFavouriteList].vessels,
      connectedImoNos,
    )

    if (disconnectedVessels.length < 100) {
      disconnectedVessels.sort(sortVesselList)
    }

    return searchUserList(
      vesselFilters.query,
      connectedVessels.concat(disconnectedVessels),
    )
  }, [imoNos, favouriteLists, selectedFavouriteList, vesselFilters])

  const vesselListHeight = useMemo(
    () => window.innerHeight - getStyleSizeFromPropName('BIG_BAR_HEIGHT') - 148,
    [],
  )

  useEffect(() => {
    applyVesselFilters({ query: '' })
    setState((prevState) => ({
      ...prevState,
      expandedIndex: undefined,
      selectedIndex: undefined,
    }))
  }, [applyVesselFilters, selectedFavouriteList])

  useEffect(() => {
    const selectedIndex = getSelectedVesselIndexFromParams(
      filteredUserList,
      +vesselId,
    )
    setState((prevState) => ({
      ...prevState,
      expandedIndex: selectedIndex,
      selectedIndex,
    }))
  }, [filteredUserList, vesselId])

  useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(
        Math.min(prevExpandedIndexRef.current ?? 0, state.expandedIndex ?? 0),
      )

      if (state.expandedIndex !== undefined) {
        listRef.current.scrollToItem(state.expandedIndex, 'start')
      }
    }
    prevExpandedIndexRef.current = state.expandedIndex
  }, [state.expandedIndex])

  if (favourites.isLoading || vesselsBasicInfo.isLoading) {
    return <Loading />
  }

  if (!vesselsBasicInfo.isSuccess) {
    return null
  }

  return (
    <Wrapper>
      <>
        <header className='vessel-title'>
          <ListDropdown
            userLists={favouriteLists as any}
            changeUserList={changeSelectedFavouriteList as any}
            currentUserList={selectedFavouriteList as any}
          />
        </header>
        <BarContentShoreOverview />
        <div className='search'>
          <InputFieldFilter
            id='vessel-search'
            filter={vesselFilters.query}
            change={debounce((value: string) => {
              applyVesselFilters({ ...vesselFilters, query: value })
            }, 300)}
            placeholder='Type name, class or IMO'
            iconColor='#fff'
            color={maerskBlue[500]}
          />
        </div>
        {!state.loadingUserList && filteredUserList.length > 0 && (
          <List
            ref={listRef}
            className='vessels'
            itemCount={filteredUserList.length}
            height={vesselListHeight}
            width={getStyleSizeFromPropName('NAVBAR_WIDTH')}
            overscanCount={10}
            style={{ transitionDuration: '0.5s' }}
            itemSize={(index) =>
              index === state.expandedIndex
                ? pageSettings.filter(
                    (page) => !page.hideInNav && !page.isDisabled,
                  ).length *
                    40 +
                  48
                : 48
            }
          >
            {({ index, style }) => {
              const { imoNo, vesselName } = filteredUserList[index]
              const vesselExpanded = index === state.expandedIndex
              const currentVessel = `${imoNo}` === vesselId
              const vesselStatus =
                sensorsStatuses.data?.find(
                  (sensorStatus: SensorStatusApi.Sensors.Status) =>
                    sensorStatus.imoNo === imoNo,
                ) ?? undefined

              return (
                <li style={style}>
                  <VesselPanel
                    vesselStatus={vesselStatus}
                    vesselName={vesselName}
                    imoNo={imoNo}
                    active={currentVessel}
                    vesselExpanded={vesselExpanded}
                    vesselActive={isConnectedVessel(String(imoNo), imoNos)}
                    onClick={() => {
                      setState({
                        ...state,
                        expandedIndex:
                          index !== state.expandedIndex ? index : undefined,
                        selectedIndex: index,
                      })
                    }}
                  />
                </li>
              )
            }}
          </List>
        )}
        {state.loadingUserList && <Loading />}
      </>
    </Wrapper>
  )
}

export default BarContentShore
